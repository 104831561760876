import React, {useEffect} from 'react';
import {matchPath, useLocation} from 'react-router-dom';
// Utilities
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';

// Material Components
import {Box, Drawer, Hidden, List} from '@mui/material';

import NavItem from './NavItem';

// Custom SVGs
import HomeIcon from '../../../../assets/svgs/HomeIcon';
import MyOrders from '../../../../assets/svgs/MyOrders';
import MyAssets from '../../../../assets/svgs/MyAssets';
import MyPayments from '../../../../assets/svgs/MyPayments';
import secureLocalStorage from "react-secure-storage";
import mixpanel from 'mixpanel-browser';


const sections = [
    {
        items: [
            {
                title: 'Home',
                icon: () => (
                    <HomeIcon style={{paddingRight: "7%"}}/>
                ),
                href: '/dashboard'
            },
            {
                title: 'My Orders',
                icon: () => (
                    <MyOrders style={{paddingRight: "7%"}}/>
                ),
                href: '/myOrders'
            },
            {
                title: 'My Assets',
                icon: () => (JSON.parse(secureLocalStorage.getItem('datum_count')) > 0 ?
                        <img
                            alt="asset"
                            src='/static/images/icons/asset/Datum.svg'
                            style={{
                                width: '20px',
                                height: '20px',
                                // borderRadius: "100px",
                                paddingRight: "7%",
                                marginLeft: '-2px'
                            }}
                        /> :
                        <MyAssets style={{paddingRight: "7%"}}/>
                ),
                href: '/newasset'
            },
            {
                title: "My Payments",
                icon: () => (
                    <MyPayments style={{paddingRight: "7%"}}/>
                ),
                href: '/payments'
            },

            // Temprory

            // {
            //     title: "Asset Despence",
            //     // icon: () => (
            //     //     <MyAssets style={{ paddingRight: "7%" }} />
            //     // ),
            //     href: '/despence'
            // },
            // {
            //     title: "Asset Refill",
            //     // icon: () => (
            //     //     <MyAssets style={{ paddingRight: "7%" }} />
            //     // ),
            //     href: '/refill'
            // },
            // {
            //     title: "Asset Flow",
            //     // icon: () => (
            //     //     <MyAssets style={{ paddingRight: "7%" }} />
            //     // ),
            //     href: '/assets'
            // },

        ]
    }
]

const renderNavItems = ({items, pathname, depth = 0, play}) => {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) => reduceChildRoutes({acc, item, pathname, depth, play}),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({acc, pathname, item, depth, play}) {
    const key = item.title + depth;

    const handleClick = () => {
        // Use item.title directly in the event name
        mixpanel.track(`${item.title} Clicked`, {
            'Item Title': item.title,
            'Item URL': item.href,
        });
    };

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                open={Boolean(open)}
                title={item.title}
                play={play}
                onClick={handleClick}  // Track clicks for parent items

            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items,
                    play: play
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                info={item.info}
                key={key}
                title={item.title}
                play={play}
                onClick={handleClick}  // Track clicks for individual items

            />
        );
    }

    return acc;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)',

    },

}));

const NavBar = ({onMobileClose, openMobile, play}) => {

    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box height="100%" display="flex" flexDirection="column" backgroundColor="#f5f5f5">
            <PerfectScrollbar options={{suppressScrollX: true}}>
                <Hidden lgUp>
                    <Box p={2} display="flex" justifyContent="center">
                        {/* <RouterLink to="/">*/}
                        {/*    <Logo/>*/}
                        {/*</RouterLink>*/}
                    </Box>
                </Hidden>
                <Box p={2}>
                </Box>
                <Box p={3}>
                    {
                        sections?.map((section, key) => (
                            <List
                                key={section.subheader}
                            >
                                {
                                    renderNavItems({
                                        items: section.items,
                                        pathname: location.pathname,
                                        play: play
                                    })
                                }
                            </List>
                        ))
                    }
                </Box>
            </PerfectScrollbar>
        </Box>
    )
    return (
        <>
            {/*<Hidden lgUp>*/}
            {/*    <Drawer*/}
            {/*        anchor="left"*/}
            {/*        classes={{paper: classes.mobileDrawer}}*/}
            {/*        onClose={onMobileClose}*/}
            {/*        open={openMobile}*/}
            {/*        variant="temporary"*/}
            {/*    >*/}
            {/*        {content}*/}
            {/*    </Drawer>*/}
            {/*</Hidden>*/}
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.desktopDrawer}}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    )
}

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
}
export default NavBar

