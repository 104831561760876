import axios from 'axios';
//Import Mixpanel SDK
import mixpanel from "mixpanel-browser";

/***** staging ******/
 
// Near entry of your product, init Mixpanel
mixpanel.init("a0d8b7a5af680504cc3694153bba003a", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

export const reposWebApi = axios.create({
    baseURL: 'https://staging.reposenergy.com/api/web/v1'
});
export const fileUploadServiceApi = axios.create({
    baseURL: 'https://uploads-staging.reposenergy.com/api/customers'
});
export const client_secret = 'C7Im2ydJp15Jz9tgRd9q0b2RmLHJUkyordU2I4o82vOcsjtFeAk3e2PEOP5Q18t1hAu9noVAPUqqJudGNfUfy1M5luvCaGsUK4MWCBUv0ypnAg7Rc5dHyITMtO35VlAI';
export const client_id = 'rF8dDokEPCLbsXBrnOUkFRhnK7lo7qp7lhbpd5z8';
export const agreement = 'https://staging.reposenergy.com/api/web/v1/finserv/agreement/download/agreement.pdf?id=';

export const nodeStaging = axios.create({
    // baseURL: 'http://3.6.126.216:8002/api/node'
    baseURL: 'https://dpu-stage.reposenergy.com/api/node'
})

// --------------------- new staging ----------------

// export const reposWebApi = axios.create({
//     baseURL: 'https://new-server.reposenergy.com/api/web/v1'
// });
// export const fileUploadServiceApi = axios.create({
//     baseURL: 'https://uploads-staging.reposenergy.com/api/customers'
// });
// export const client_secret = 'njExaFjqawQaNSpg6TIJ4cY3QxHXctZumpx81LN2AbBI77XSXb4RDKgrBdVWvl1C5oVL24AFq08l97MPxwUAuFcFAHnSOrrzzC8Q0i4deUdRHUjs9UsOL5McbmNkpAoP';
// export const client_id = 'ret1RBCWi8AErtlxKGYpY8CXsfciFt3Xc0zpYOJS';
// export const agreement = 'https://staging.reposenergy.com/api/web/v1/finserv/agreement/download/agreement.pdf?id=';
// export const nodeStaging = axios.create({
//     // baseURL: 'http://3.6.126.216:8002/api/node'
//     baseURL: 'https://dpu-stage.reposenergy.com/api/node'
// })

/***** live ******/

// export const nodeStaging = axios.create({
//     // baseURL: 'http://3.6.126.216:8002/api/node'
//     baseURL: 'https://dpu-prod.reposenergy.com/api/node'
// })
// export const reposWebApi = axios.create({
//     baseURL: 'https://drf.reposenergy.com/api/web/v1'
// });
// export const fileUploadServiceApi = axios.create({
//     baseURL: 'https://uploads-drf.reposenergy.com/api/customers'
// });
// export const client_secret = 'njExaFjqawQaNSpg6TIJ4cY3QxHXctZumpx81LN2AbBI77XSXb4RDKgrBdVWvl1C5oVL24AFq08l97MPxwUAuFcFAHnSOrrzzC8Q0i4deUdRHUjs9UsOL5McbmNkpAoP';
// export const client_id = 'ret1RBCWi8AErtlxKGYpY8CXsfciFt3Xc0zpYOJS';
// export const agreement = 'https://drf.reposenergy.com/api/web/v1/finserv/agreement/download/agreement.pdf?id=';

//****************  appVersion version and tracker for QR *****************//

export const CaptchaKey = "6Ld7I8IoAAAAANSRk1ZYr_zHPAx68BR3V9iq3qbP"
export const appVersion = "V.4.0.9";

export const trackerForQr = 'D00158';