import * as actionTypes from '../actions';
import {GET_DATUM_ENQUIRY_FAIL} from "../actions";

const initialState = {
    windowSize: 'sm',
    isLoading: false,
    sessionExpired: false,
    DatumOrder: [],
    creatSubAsset: '',
    DatumASSETS: [],
    datumASSETSCount: 0,
    DatumNext: true,
    deleteSubAsset: '',
    updateSubAsset: '',
    profileDetails: '',
    subAssetToBeEdited: [],
    DatumOrderCount: 0,
    DatumOrderNext: null,
    isSensorLoading: false,
    sensorData: [],
    get_telimatics: [],
    is_telimatics: false,
    datumOrderStatus: '',
    rcDetails: '',
    noDataFound: false,
    OpenDetails: false,
    datumData: [],
    isDatum: false,
    isDashboard: false,
    Elocks :[],
    ElockCount:0,
    ElockNext:null
};

function getUnique(array, key) {
    if (typeof key !== 'function') {
        const property = key;
        key = function (item) {
            return item[property];
        };
    }
    return Array.from(array.reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
    }, new Map()).values());
}

const datumReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DATUM_LOADING: {
            return {
                ...state,
                isLoading: true,
                sessionExpired: false,

            };
        }
        case actionTypes.DATUM_LOADING_FALSE: {
            return {
                ...state,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.DATUM_ORDERS: {
            return {
                ...state,
                DatumOrder: getUnique(state?.DatumOrder?.concat(action.payload?.results), "id"),
                DatumOrderCount: action.payload?.count,
                DatumOrderNext: action.payload?.next,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.SET_DATA: {
            return {
                ...state,
                datumData: action?.payload?.datumData,
                isDatum: action?.payload?.isDatum,
                OpenDetails: action?.payload?.OpenDetails,
                assetType: action?.payload?.assetType,
                isDashboard: action?.payload?.isDashboard,
                assetId:action?.payload?.assetId
            }
        }
        case actionTypes.DATUM_ORDER_ACCEPT: {
            return {
                ...state,
                DatumOrder: []
            }
        }
        case actionTypes.DATUM_ASSET_LIST: {
            return {
                ...state,
                DatumASSETS: state.DatumASSETS.concat(action.payload?.results),
                datumASSETSCount: action.payload?.count,
                DatumNext: action.payload?.next,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.DATUM_ASSET_CREATE: {
            return {
                ...state,
                creatSubAsset: action.payload,
                DatumASSETS: [],
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.DATUM_ASSET_DELETE: {
            return {
                ...state,
                deleteSubAsset: action.payload,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.DATUM_ASSET_UPDATE: {
            return {
                ...state,
                updateSubAsset: action.payload,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.DATUM_ORDER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                profileDetails: action?.payload,
            };
        }
        case actionTypes.DATUM_ORDER_UPDATE: {
            return {
                ...state,
                isLoading: false,

            }
        }
        case actionTypes.SHELL_DATA: {
            return {
                ...state,
                isShellLoading: false,
                SHELL_DATA: action?.payload,
            };
        }
        case actionTypes.SHELL_LOADING: {
            return {
                ...state,
                isShellLoading: true,
                sessionExpired: false,

            };
        }
        case actionTypes.GET_SINGLE_SUB_ASSET: {
            return {
                ...state,
                isShellLoading: false,
                subAssetToBeEdited: action?.payload,
                sessionExpired: false,
                assetModified: false,
            };
        }
        case actionTypes.SENSOR_DATA: {
            return {
                ...state,
                isSensorLoading: false,
                sensorData: action?.payload?.results,
                sessionExpired: false,
            };
        }
        case actionTypes.SENSOR_DATA_LOADING: {
            return {
                ...state,
                isSensorLoading: true,
            };
        }
        case actionTypes.CLEAR_SUB_ASSET: {
            return {
                ...state,
                subAssetToBeEdited: [],
                DatumOrder: [],
                DatumASSETS: [],
                datumASSETSCount: 0
            }
        }
        case actionTypes.GET_TELEMATICS_TYPE: {
            return {
                ...state,
                is_telimatics: true,
                get_telimatics: action?.payload,
            }
        }
        case actionTypes.DATUM_ORDER_STATUS: {
            return {
                ...state,
                datumOrderStatus: action.payload
            }
        }
        case  actionTypes.DATUM_ORDER_STATUS_NULL: {
            return {
                ...state,
                datumOrderStatus: ''
            }
        }
        case actionTypes.GET_RC_DETAILS: {
            return {
                ...state,
                isLoading: false,
                rcDetails: action.payload,
            }
        }
        case actionTypes.GET_RC_DETAILS_NOT_FOUND: {
            return {
                ...state,
                rcDetails: action.payload,
                noDataFound: true,
                isLoading: false
            }
        }
        case actionTypes.GET_RC_DETAILS_FAIL: {
            return {
                ...state,
                rcDetails: action.payload,
                isLoading: false
            }
        }
        case actionTypes.GET_DATUM_ENQUIRY_FAIL:{
            return{
                ...state,
                isLoading: false
            }
        }
        case actionTypes.GET_DATUM_ENQUIRY:{
            return{
                ...state,
                isLoading: false
            }
        }
        case actionTypes.ELOCK_LOADING: {
            return {
                ...state,
                isLoading: true,
                sessionExpired: false,

            };
        }
        case actionTypes.ALL_ELOCK: {
            return {
                ...state,
                Elocks: getUnique(state?.Elocks?.concat(action.payload?.results), "id"),
                ElockCount: action.payload?.count,
                ElockNext: action.payload?.next,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.ELOCK_ACCEPT: {
            return {
                ...state,
                Elocks: []
            }
        }
        case actionTypes.GET_ASSETS_FAIL: {
            return {
                ...state,
                isLoading: false,
                sessionExpired: false,
                error: true,
            };
        }
        case actionTypes.GET_ASSET_LIST: {

            return {
                ...state,
                isAssetListLoading: false,
                sessionExpired: false,
                assetList: action?.payload,
                assetIds: action?.payload?.map((item) => item?.id)
            }

        }
        default: {
            return {
                ...state,
            };
        }
    }
};


export default datumReducer;