import * as actionTypes from '../actions';
import secureLocalStorage from "react-secure-storage";
import {GET_MAIL_REPORTS_FAIL} from "../actions";

var assetLocatlData = JSON.parse(secureLocalStorage.getItem('assetCount'));
var asset_type = JSON.parse(secureLocalStorage.getItem('asset_type'));

var initialState = {
    sessionExpired: false,
    assetData: assetLocatlData?.data?.results,
    assetCreated: false,
    orderLoading: false,
    isLoading: false,
    isAssetListLoading: true,
    assetCreationFail: false,
    assetCreatedMsg: "",
    assetDeletedMsg: null,
    assetToBeEdited: null,
    assetModified: false,
    dispenseReports: [],
    createDispenseEntry: null,
    refillReports: [],
    cityData: [],
    cityCount: null,
    dataCount: null,
    error: false,
    // setDate: format( data,'dd-MM-yyyy'),
    setDate: '',
    rppData: [],
    next: null,
    createDispenseEntryFail: null,
    assetDataCount: assetLocatlData?.data?.count,
    assetDetails: {
        asset: "",
        pickup: "",
        is_finserv_enabled: "",
    },
    assetTypeCount: asset_type?.data?.data === undefined ? 0 : asset_type?.data?.data,
    isAssetTypeLoading: true,
    outOfService: [],
    assetLocation: undefined,
    isCityLoading: false,
    isDatumLoading: false,
    healthData: [],
    datumGraphDetails: [],
    subAssetGraphDetails: [],
    datumGraphDetailsError: '',
    refillDataCount: 0,
    dispenseNext: null,
    refillNext: null,
    placeNewOrder: false,
    assetList: [],
    analyticsReport: '',
    subAssetAnalyticsReport: '',
    isSubAssetLoading: true,
    isAnalyticsLoading: true,
    date: '',
    assetIds: [],
};

function getUnique(array, key) {
    if (typeof key !== 'function') {
        const property = key;
        key = function (item) {
            return item[property];
        };
    }
    return Array.from(array.reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
    }, new Map()).values());
}

const assetReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ASSETS: {

                return {
                    ...state,
                    assetData: getUnique(state.assetData.concat(action?.payload.results), "id"),
                    assetDataCount: action?.payload?.count,
                    next: action?.payload?.next,
                    createDispenseEntryFail: null,
                    sessionExpired: false,
                    isLoading: false,
                    assetModifiedFail: false,
                    assetModified: false,
                    assetCreated: false,
                    error: false,
                };

        }
        case actionTypes.GET_ASSETS_FAIL: {
            return {
                ...state,
                isLoading: false,
                sessionExpired: false,
                error: true,
            };
        }
        case actionTypes.GET_ASSET_LIST: {

            return {
                ...state,
                isAssetListLoading: false,
                sessionExpired: false,
                assetList: action?.payload,
                assetIds: action?.payload?.map((item) => item?.id)
            }

        }

        case actionTypes.GET_DATE: {
            return {
                ...state,
                setDate: action?.payload,
            };
        }
        case actionTypes.ASSET_LOADING: {
            return {
                ...state,
                isLoading: true,
                sessionExpired: false,
                assetDeletedMsg: null,
                error: false,
            };
        }

        case actionTypes.SESSION_EXPIRED: {
            return {
                ...state,
                sessionExpired: true,
                isLoading: false,
                error: false
            };
        }
        case actionTypes.CREATING_NEW_ASSET: {
            return {
                ...state,
                isLoading: true,
                error: false
            };
        }
        case actionTypes.IS_CITY_LOADING: {
            return {
                ...state,
                isCityLoading: true,
                error: false
            };
        }
        case actionTypes.CREATE_ASSET: {
            return {
                ...state,
                isLoading: false,
                assetCreated: true,
                assetCreatedMsg: action?.payload,
                sessionExpired: false,
                error: false
            };
        }
        case actionTypes.EDIT_ASSET: {
            return {
                ...state,
                isLoading: false,
                error: false,
                assetModified: true,
                assetCreatedMsg: action?.payload,
                sessionExpired: false,
                assetModifiedFail: false,
            };
        }
        case actionTypes.CHANGE_ASSET_PASSWORD: {
            return {
                ...state,
                isLoading: false,
                error: false,
                assetModified: true,
                assetCreatedMsg: action?.payload,
                sessionExpired: false,
                assetModifiedFail: false,
            };
        }
        case actionTypes.EDIT_ASSET_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: false,
                assetModified: false,
                assetModifiedFail: true,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_DISPENSE_REPORTS: {
            return {
                ...state,
                isLoading: false,
                error: false,
                assetModified: true,
                dispenseReports: state?.dispenseReports.concat(action?.payload?.results),
                dataCount: action?.payload?.count,
                sessionExpired: false,
                dispenseNext: action?.payload?.next
            };


        }
        case actionTypes.GET_DISPENSE_REPORTS_FAIL: {
            return {
                ...state,
                error: false,
                isLoading: false,
                assetModified: true,
                assetModifiedFail: true,
                dataCount: action?.payload?.length,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_SINGLE_ASSET: {
            return {
                ...state,
                error: false,
                isLoading: false,
                assetToBeEdited: action?.payload,
                sessionExpired: false,
                assetModified: false,
            };
        }
        case actionTypes.GET_ANALYTICS_REPORT: {
            return {
                ...state,
                analyticsReport: action?.payload?.results,
                reportCount: action?.payload?.count,
                sessionExpired: false,
                isAnalyticsLoading: false
            }
        }
        case actionTypes.GET_ANALYTICS_REPORT_FAIL: {
            return {
                ...state,
                isAnalyticsLoading: false,
                analyticsReport: action?.payload?.results,
                sessionExpired: false
            }
        }
        case actionTypes.SUB_ASSET_ANALYTICS_LOADING: {
            return {
                ...state,
                isSubAssetLoading: true,
            }
        }
        case actionTypes.SET_FORM_DATA: {
            return {
                ...state,
                date: action?.payload
            }
        }
        case actionTypes.GET_SUB_ANALYTICS_REPORT: {
            return {
                ...state,
                isSubAssetLoading: false,
                subAssetAnalyticsReport: action?.payload,
                sessionExpired: false,
            }
        }
        case actionTypes.GET_SUB_ANALYTICS_REPORT_FAIL: {
            return {
                ...state,
                isSubAssetLoading: false,
                subAssetAnalyticsReport: action?.payload,
                sessionExpired: false
            }
        }
        case actionTypes.GET_SINGLE_RPP: {
            return {
                ...state,
                error: false,
                isLoading: false,
                rppData: action?.payload,
                sessionExpired: false,
                assetModified: false,
            };
        }
        case actionTypes.GET_RPP_LIST: {
            return {
                ...state,
                error: false,
                isLoading: false,
                assetDetails: action?.payload,
                sessionExpired: false,
                assetModified: false,
            };
        }
        case actionTypes.CREATE_ASSET_FAIL: {
            return {
                ...state,
                error: false,
                isLoading: false,
                assetCreated: false,
                assetCreationFail: action?.payload,
                sessionExpired: false,
            };
        }
        case actionTypes.DELETING_ASSET: {
            return {
                ...state,
                error: false,
                isLoading: true,
                sessionExpired: false,
            };
        }
        case actionTypes.DELETE_ASSET: {
            return {
                ...state,
                error: false,
                isLoading: false,
                assetDeletedMsg: action?.payload,
                sessionExpired: false,
            };
        }
        case actionTypes.DELETE_ASSET_FAIL: {
            return {
                ...state,
                error: false,
                isLoading: false,
                assetDeletedMsg: action?.payload,
                sessionExpired: false,
            };
        }
        case actionTypes.CREATE_DISPENSE: {
            return {
                ...state,
                error: false,
                isLoading: false,
                createDispenseEntry: action?.payload,
                sessionExpired: false,
            };
        }
        case actionTypes.CREATE_DISPENSE_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: false,
                createDispenseEntryFail: action?.payload,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_REFILL_REPORTS: {
            return {
                ...state,
                error: false,
                isLoading: false,
                refillReports: getUnique(state?.refillReports.concat(action?.payload.results), "id"),
                refillDataCount: action?.payload?.count,
                sessionExpired: false,
                refillNext: action?.payload?.next
            };
        }
        case actionTypes.GET_REFILL_REPORTS_NOT_FOUND: {
            return {
                ...state,
                error: true,
                isLoading: false,
                sessionExpired: false,
                refillDataCount: 0,
            };
        }
        case actionTypes.GET_REFILL_REPORTS_FAIL: {
            return {
                ...state,
                error: true,
                isLoading: true,
                sessionExpired: false,
            };
        }
        case actionTypes.ASSET_TYPE_COUNT_LOADING: {
            return {
                ...state,
                error: false,
                isAssetTypeLoading: true,
                sessionExpired: false,
            };
        }
        case actionTypes.ASSET_LOCATION: {
            return {
                ...state,
                error: false,
                assetLocation: action?.payload,
                sessionExpired: false,
            }
        }
        case actionTypes.ASSET_TYPE_COUNT: {
            return {
                ...state,
                error: false,
                isAssetTypeLoading: false,
                assetTypeCount: action?.payload,
                sessionExpired: false,
            };
        }
        case actionTypes.ASSET_OUT_OF_SERVICE: {
            return {
                ...state,
                error: false,
                isLoading: false,
                outOfService: action?.payload,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_CITY_ASSET: {
            return {
                ...state,
                error: false,
                isCityLoading: false,
                cityData: action?.payload,
                cityCount: action?.payload?.length,
                sessionExpired: false,
            }
        }

        case actionTypes.DATUM_HEALTH_LOADING: {
            return {
                ...state,
                isDatumLoading: true,
                sessionExpired: false,
                error: false,
            };
        }
        case actionTypes.DATUM_HEALTH: {
            return {
                ...state,
                isDatumLoading: true,
                healthData: action?.payload?.data,
                sessionExpired: false,
                error: false,
            }
        }
        case actionTypes.GET_DATUM_GRAPH_DETAILS: {
            return {
                ...state,
                datumGraphDetails: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_DATUM_GRAPH_DETAILS_FAIL: {
            return {
                ...state,
                datumGraphDetailsError: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_SUBASSET_GRAPH_DETAILS : {
            return {
                ...state,
                subAssetGraphDetails: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_SUBASSET_GRAPH_DETAILS_FAIL : {
            return {
                ...state,
                subAssetGraphDetails: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.CLEAR_ASSET_DATA: {
            return {
                ...state,
                isLoading: true,
                assetData: [],
                refillReports: [],
                dispenseReports: [],
            }
        }
        case actionTypes.PLACE_ORDER_PAGE: {
            return {
                ...state,
                placeNewOrder: action.payload
            }
        }
        case actionTypes.GET_MAIL_REPORTS_FAIL:{
            return {
                ...state,
                isLoading: false,
                sessionExpired: false,
            }
        }
        case actionTypes.GET_MAIL_REPORTS:{
            return {
                ...state,
                isLoading: false,
                sessionExpired: false,
            }
        }
        case actionTypes.GET_DOWNLOAD_REPORTS_FAIL:{
            return {
                ...state,
                isLoading: false,
                sessionExpired: false,
            }
        }
        case actionTypes.GET_DOWNLOAD_REPORTS:{
            return {
                ...state,
                isLoading: false,
                sessionExpired: false,
            }
        }
        default: {
            return {
                ...state,
            };
        }
    }
};


export default assetReducer;
