import React, {useState} from 'react'
import {Link as RouterLink} from 'react-router-dom';

import {
    AppBar,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Hidden,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    SwipeableDrawer,
    Toolbar,
    Typography
} from "@mui/material";

import Logo from '../../../../components/Logo';
import {THEMES} from '../../../../costants';

import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Notification from './Notification';
import Profile from './Profile';
import OrderDialog from "../PlaceOrderDialog";
import {useDispatch, useSelector} from "react-redux";
import {getNotification} from "../../../../actions";
import {useSnackbar} from "notistack";
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...(theme.name === THEMES.LIGHT
            ? {
                boxShadow: 'none',
                backgroundColor: theme.palette.primary.main
            }
            : {}),
        ...(theme.name === THEMES.ONE_DARK
            ? {
                backgroundColor: theme.palette.background.default
            }
            : {})
    },
    toolbar: {
        minHeight: 64
    },
    appBarTransparent: {
        // backgroundColor:'rgba(67,129,168,0.5)'
    },
    buttonClass: {
        backgroundColor: "#02A29F",
        color: '#fff',
        //borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(1),
        boxShadow: '0px 1px 11px 6px rgb(229 236 236 / 37%)',
        '&:hover': {
            boxShadow: 'inset 0px 0px 7px 0px rgb(27 67 67 / 37%)',
            backgroundColor: "#02A29F",
        },
    },
    buttonClass2: {
        backgroundColor: "#c5cbcb",
        color: '#ffffff',
        //borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(1),
        boxShadow: '0px 1px 11px 6px rgb(229 236 236 / 37%)',
        '&:hover': {
            boxShadow: 'inset 0px 0px 7px 0px rgb(27 67 67 / 37%)',
            backgroundColor: "#8d9898",
        },
    },
}));


const TopBar = ({className, onMobileNavOpen, Online, Offline, ...rest}) => {
    const assets = useSelector(state => state?.assetReducer);
    const networkError = useSelector(state => state?.getNotify);
    const notify = useSelector(state => state?.getNotify);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        top: false,
    });
    const {enqueueSnackbar} = useSnackbar();

    const handleOpenDialog = () => {
        setOpen(true);
        mixpanel.track('New Order Button Clicked');
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleGetNotify = () => {
        dispatch(getNotification(enqueueSnackbar))
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        if (open) {
            handleGetNotify()
        }

        setState({...state, [anchor]: open});
    };
    const list = (anchor) => (
        <Box
            mt={5}
            sx={{width: 250, background: "#F5F5F5"}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {
                    notify?.notificationLoading ?
                        <LinearProgress/> :
                        notify?.notify_list.map((text, index) => (
                            <ListItem key={text} disablePadding>
                                <ListItemButton component={RouterLink}
                                                to={{
                                                    pathname: `/myOrders`,
                                                    state: {
                                                        id: text?.id,
                                                    }
                                                }}
                                >
                                    <Box display="flex" flexDirection="column" mt={3}>
                                        <Card>
                                            <CardContent>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="space-between"
                                                >
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        direction="column"
                                                        justify="center"
                                                    >
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <Grid item backgroundColor="#F5F5F5">
                                                                <Typography variant="bodyForNotify2">
                                                                    Order ID:
                                                                    {text?.id === "" ||
                                                                    text?.id === null
                                                                        ? "N/A"
                                                                        : text?.id}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {/*<MoreVertIcon/>*/}
                                                </Box>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    direction="column"
                                                    justify="center"
                                                >
                                                    <Grid item>
                                                        <Grid item xs={11}>
                                                            <Box
                                                                mt={1}
                                                                mb={1}
                                                                display="flex"
                                                                flexDirection="row"
                                                                justifyContent="space-between"
                                                            >
                                                                <Typography variant="body2">
                                                                    {text?.text}
                                                                </Typography>
                                                            </Box>
                                                            <Box display={"flex"} flexDirection="column">
                                                                <Typography variant="h6" fontWeight="600">
                                                                    Order Date : {text?.created_at}
                                                                </Typography>
                                                                <Typography variant="h6" fontWeight="600">
                                                                    Delivery Date : {text?.updated_at}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </ListItemButton>
                            </ListItem>
                        ))}
            </List>
        </Box>
    );

    const classes = useStyles();
    return (
        <AppBar className={clsx(classes.root, classes.appBarTransparent, className)}
                style={{backgroundColor: "#ffffff"}} {...rest}>
            <Toolbar className={classes.toolbar}>
                <RouterLink to="/dashboard">
                    <Logo/>
                </RouterLink>
                <Box ml={2} flexGrow={1}/>
                {networkError?.IsNetworkError && <Box>
                    <Typography variant={"body"} color={"#d32121"}>
                        No Internet
                    </Typography>
                </Box>}
                {
                    <React.Fragment key={'top'}>
                        <IconButton onClick={toggleDrawer('top', true)}>
                            <Notification/> {notify?.new_item.length !== 0 &&
                            <Typography style={{marginTop: '-24px', marginLeft: '-17px'}} variant={"bodyForNotify"}
                                        color={"rgb(239, 83, 80)"}>{notify?.new_item.length}< /Typography>}
                        </IconButton>
                        <SwipeableDrawer
                            anchor={'right'}
                            open={state['top']}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            {list('top')}
                        </SwipeableDrawer>
                    </React.Fragment>
                }

                <Profile/>
                <Hidden lgDown>
                    <Button
                        disabled={assets?.placeNewOrder}
                        onClick={handleOpenDialog}
                        className={assets?.placeNewOrder ? classes.buttonClass2 : classes.buttonClass}
                    >New Order</Button>
                </Hidden>
            </Toolbar>
            <OrderDialog open={open} handleClose={handleClose}/>
        </AppBar>
    )
}
TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
    onMobileNavOpen: () => {
    }
};
export default TopBar