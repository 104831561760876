import * as actionTypes from '../actions';

var initialState = {
    refuellers: [],
    isLoading: true,
    convCharges: '',
    orderFail: '',
    getOrderError: '',
    count: 0,
    singleOrder: [],
    currentOrder: [],
    ongoingOrder: [],
    currentOrderCount: 0,
    pastOrder: [],
    pastOrderCount: 0,
    overdueOrder: [],
    placeOrder: [],
    allOrders: [],
    accept: '',
    refuelerCount: '',
    sessionExpired: false,
    orderAlreadyPlaced: false,
    statusCode: 0,
    orderPaymentInfo: null,
    orderSuccess: null,
    orderNumber: null,
    isPayLoading: false,
    cancelOrder: [],
    energyScore: '',
    allOrdersNext: null,
    currentOrdersNext: null,
    pastOrdersNext: null
};

function getUnique(array, key) {
    if (typeof key !== 'function') {
        const property = key;
        key = function (item) {
            return item[property];
        };
    }
    return Array.from(array.reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
    }, new Map()).values());
}

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_REFUELERS: {
            return {
                ...state,
                refuellers: action.payload.refuelers,
                isLoading: false,
                refuelerCount: action.payload.refuelers.length,
                sessionExpired: false,
                orderAlreadyPlaced: false
            };
        }
        case actionTypes.SESSION_EXPIRED: {
            return {
                ...state,
                sessionExpired: true,
                isLoading: false
            };
        }
        case actionTypes.ORDER_LOADING: {
            return {
                ...state,
                isLoading: true,
                sessionExpired: false,

            };
        }
        case actionTypes.PAY_LOADING: {
            return {
                ...state,
                isPayLoading: true,
                openPayDialog: false,
                sessionExpired: false,

            };
        }
        case actionTypes.GET_CONVENIENCE: {
            return {
                ...state,
                convCharges: action.payload,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.ORDER_ALREADY_PRESENT: {
            return {
                ...state,
                convCharges: null,
                orderAlreadyPlaced: true,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.PROCESS_ORDER_DIALOG: {
            return {
                ...state,
                orderAlreadyPlaced: false,
                isLoading: false,
            };
        }
        case actionTypes.PLACE_ORDER_FAIL: {
            return {
                ...state,
                orderFail: action.payload,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.GET_ALL_ORDER: {
            return {
                ...state,
                allOrders: getUnique(state.allOrders.concat(action.payload.results), 'id'),
                count: action.payload.count,
                isLoading: false,
                sessionExpired: false,
                allOrdersNext: action?.payload?.next

            };
        }
        case actionTypes.GET_ALL_DATUM_ORDER_REQ: {
            return {
                ...state,
                accept: action.payload?.data,
                statusCode: action.payload?.status,
                isLoading: false,
                sessionExpired: false,
            }
        }
        case actionTypes.GET_PLACE_ORDER: {
            return {
                ...state,
                placeOrder: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_PAYMENT: {
            return {
                ...state,
                orderSuccess: action.payload,
                orderPaymentInfo: action.payload?.data,
                orderNumber: action.payload?.data?.order_id,
                isPayLoading: false,
                openPayDialog: true,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_PLACE_ORDER_FAIL: {
            return {
                ...state,
                getOrderError: action.payload,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.GET_ALL_ORDERS_FAIL: {
            return {
                ...state,
                getOrderError: action.payload,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.GET_SINGLE_ORDER: {
            return {
                ...state,
                singleOrder: action.payload,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.GET_CURRENT_ORDER: {
            return {
                ...state,
                currentOrder: getUnique(state.currentOrder.concat(action.payload.results), "id"),
                count: action.payload.count,
                isLoading: false,
                sessionExpired: false,
                currentOrdersNext: action?.payload?.next

            };
        }
        case actionTypes.GET_ONGOING_ORDER: {
            return {
                ...state,
                ongoingOrder: action?.payload?.results,
                count: action.payload.count,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.GET_PAST_ORDER: {
            return {
                ...state,
                // ongoingOrder: action.payload===undefined? 0: action.payload ,
                pastOrder: getUnique(state.pastOrder.concat(action.payload.results), 'id'),
                count: action?.payload?.count,
                isLoading: false,
                sessionExpired: false,
                pastOrdersNext: action.payload?.next
            };
        }
        case actionTypes.GET_OVERDUE_ORDER: {
            return {
                ...state,
                // overdueOrder: action.payload===undefined? 0: action.payload,
                overdueOrder: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_COUNT: {
            return {
                ...state,
                // isLoading:false,
            }
        }
        case actionTypes.EDIT_RECEIVER: {
            return {
                ...state,
                isLoading: false,
            }

        }
        case actionTypes.EDIT_RECEIVER_LOADING: {

            return {
                ...state,
                isLoading: true,
            }

        }
        case actionTypes.EDIT_RECEIVER_FAIL: {
            return {
                ...state,
                isLoading: false,
            }

        }
        case actionTypes.CANCEL_ORDER_LOADING: {
            return {
                ...state,
                isLoading: false,
            }

        }
        case actionTypes.CANCEL_ORDER: {
            return {
                ...state,
                isLoading: false,
                cancelOrder: action?.payload
            }

        }
        case actionTypes.CANCEL_ORDER_FAIL: {
            return {
                ...state,
                isLoading: false,
                cancelOrder: action?.payload
            }
        }
        case actionTypes.GET_ENERGY_SCORE: {
            return {
                ...state,
                isLoading: false,
                energyScore: action?.payload
            }
        }
        case actionTypes.GET_ENERGY_SCORE_FAIL: {
            return {
                ...state,
                isLoading: false,
                energyScore: action?.payload
            }
        }
        case actionTypes.CLEAR_ALL_DATA: {
            return {
                allOrders: [],
                currentOrder: [],
                pastOrder: [],
            }
        }
        case actionTypes.CLOSE_PAY_DIALOG: {
            return {
                ...state,
                openPayDialog: false,
            };
        }
        default: {
            return state;
        }
    }
};

export default orderReducer;
