import qs from "querystring";
import {DATUM_ORDER_ERROR, DATUM_ORDER_SUCCESS, DETAILS, FORGOT_LOADING, refreshToken} from "./sessionAction";
import {
    ASSET_LOADING,
    clearAssetData,
    GET_DATUM_GRAPH_DETAILS,
    GET_DATUM_GRAPH_DETAILS_FAIL,
    GET_DISPENSE_REPORTS,
    GET_DISPENSE_REPORTS_FAIL,
    GET_REFILL_REPORTS,
    GET_REFILL_REPORTS_FAIL,
    GET_REFILL_REPORTS_NOT_FOUND,
    getDatumGraphDetails,
    SESSION_EXPIRED
} from "./getAssetActions";
import {nodeStaging, reposWebApi} from "./customerAPI";
import secureLocalStorage from "react-secure-storage";
import {ORDER_LOADING} from "./orderActions";
import { Exposure } from "@mui/icons-material";

export const DATUM_LOADING = 'DATUM_LOADING';
export const DATUM_LOADING_FALSE = 'DATUM_LOADING_FALSE';
export const DATUM_ORDERS = 'DATUM_ORDERS';
export const DATUM_ORDER_ACCEPT = 'DATUM_ORDER_ACCEPT';
export const SOMETHING_WRONG = 'SOMETHING_WRONG';
export const DATUM_ASSET_LIST = 'DATUM_ASSET_LIST';
export const DATUM_ASSET_CREATE = 'DATUM_ASSET_CREATE';
export const DATUM_ORDER_UPDATE = 'DATUM_ORDER_UPDATE';
export const DATUM_ASSET_DELETE = 'DATUM_ASSET_DELETE';
export const DATUM_ASSET_UPDATE = 'DATUM_ASSET_UPDATE';
export const SHELL_LOADING = 'SHELL_LOADING';
export const SHELL_DATA = 'SHELL_DATA';
export const CLEAR_SUB_ASSET = "CLEAR_SUB_ASSET"
export const GET_SINGLE_SUB_ASSET = 'GET_SINGLE_SUB_ASSET';
export const SENSOR_DATA_LOADING = 'SENSOR_DATA_LOADING';
export const SENSOR_DATA = 'SENSOR_DATA';
export const GET_TELEMATICS_TYPE = 'GET_TELEMATICS_TYPE';
export const DATUM_ORDER_STATUS = 'DATUM_ORDER_STATUS';
export const DATUM_ORDER_STATUS_NULL = 'DATUM_ORDER_STATUS_NULL';
export const GET_RC_DETAILS_FAIL = 'GET_RC_DETAILS_FAIL';
export const GET_RC_DETAILS_NOT_FOUND = 'GET_RC_DETAILS_NOT_FOUND';
export const GET_RC_DETAILS = 'GET_RC_DETAILS';
export const SET_DATA = 'SET_DATA';
export const GET_DATUM_ENQUIRY = 'GET_DATUM_ENQUIRY';
export const GET_DATUM_ENQUIRY_FAIL = 'GET_DATUM_ENQUIRY_FAIL';
export const ELOCK_LOADING = "ELOCK_LOADING";
export const ALL_ELOCK = "ALL_ELOCK";
export const ELOCK_ACCEPT = "ELOCK_ACCEPT";
export const GET_ASSETS_LIST = "GET_ASSET_LIST";
export const GET_ASSET_FAIL = "GET_ASSETS_FAIL";

let search = '';
let abortsignal = '';
export const getAllCustomerOrders = (requestBody, scrollToPreview, isDashoard,fetchMoreData) => {
    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;
    return async (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: DATUM_LOADING,
                payload: false
            })
        }
        reposWebApi.get(isDashoard ? '/order/get_customer_datum_orders' : '/order/get_all_customer_order', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: requestBody,
        }).then(res => {
            dispatch({
                type: DATUM_ORDERS,
                payload: res?.data?.data
            })
            scrollToPreview();
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getAllCustomerOrders(requestBody, scrollToPreview))
            } else dispatch({
                type: SOMETHING_WRONG,
                payload: error
            });
        })
    }
}


export const acceptRejectOrder = (event, order_id, decision, enqueueSnackbar, params, scrollToPreview) => {

    return async (dispatch) => {
        dispatch({
            type: DATUM_LOADING
        })
        const requestBody = {
            order_id,
            decision
        }
        reposWebApi.post('/order/customer_order_accept_reject', qs.stringify(requestBody), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        }).then(res => {
            if (res.status === 200) {
                enqueueSnackbar('Order Accepted!', {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                dispatch({
                    type: DATUM_ORDER_ACCEPT
                })
                dispatch(clearSubAsset());
                dispatch(getAllCustomerOrders(params, scrollToPreview, false));
            } else {
                enqueueSnackbar('Order Rejected!', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                dispatch(clearSubAsset());
                dispatch(getAllCustomerOrders(params, scrollToPreview, false));
            }
        }).catch((error) => {
            if (error.response.status === 202) {
                enqueueSnackbar('Order Rejected!', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
            }
            dispatch(getAllCustomerOrders());
        })
    }
}



export const listAllSubcustomers = (requestBody, enqueueSnackbar, scrollToPreview, fetchMoreData) => {
    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;
    return async (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: DATUM_LOADING,
            });
            dispatch(clearAssetData())
        }
        reposWebApi.get('/customer/list/subcustomer_for_customer_app', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: requestBody
        }).then(async res => {
            await dispatch({
                type: DATUM_ASSET_LIST,
                payload: res?.data?.data
            })
            scrollToPreview();
        }).catch((error) => {
            if (error?.response?.status === 401) {
                enqueueSnackbar('session Expired, retrying for new session!', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                dispatch({
                    type: DATUM_LOADING_FALSE
                })
                dispatch(refreshToken())
                dispatch(listAllSubcustomers(requestBody, enqueueSnackbar, scrollToPreview))
            } else if (error?.response?.status >= 500) {
                enqueueSnackbar('Something went wrong, Please Try Again later', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                dispatch({
                    type: DATUM_LOADING_FALSE
                })
            }
            // dispatch(getAllCustomerOrders(requestBody, scrollToPreview, undefined));
        })
    }
}

export const createSubCustomer = (value, enqueueSnackbar, requestBody, scrollToPreview, handleClose, handlecall, refillGraph, capacity) => {


    return async (dispatch) => {
        dispatch({
            type: DATUM_LOADING
        })
        reposWebApi.post('/customer/manage/subcustomer', qs.stringify(value), {
                headers: {
                    Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
                }
            }
        ).then(res => {
            if (res?.status === 201) {
                enqueueSnackbar("Sub_asset Created Successfully", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
            } else if (res?.status === 200) {
                if (capacity) {
                    enqueueSnackbar("Fuel tank capacity updated successfully", {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    })
                } else {
                    enqueueSnackbar("Sub_asset Updated Successfully", {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    })
                }

            } else if (res?.status === 202) {
                enqueueSnackbar("Sub_asset Deleted Successfully", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
            } else {
                enqueueSnackbar(res?.data?.data, {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
            }
            dispatch({
                type: DATUM_ASSET_CREATE,
                payload: res?.data?.data
            })

            if (handlecall === false) {
                dispatch(getDatumRefillReports(refillGraph, enqueueSnackbar))

            } else {
                dispatch(listAllSubcustomers(requestBody, enqueueSnackbar, scrollToPreview))
            }

            // dispatch(listAllSubcustomers(requestBody, enqueueSnackbar, scrollToPreview))

            handleClose();
        }).catch(async error => {
            if (error?.response?.status === 406) {
                enqueueSnackbar('Mobile already exists!', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                dispatch({
                    type: DATUM_LOADING_FALSE
                })
            } else if (error.response?.status === 205) {
                enqueueSnackbar('Mobile already exists and is de-active', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                dispatch({
                    type: DATUM_LOADING_FALSE
                })
            } else if (error.response?.status === 401) {
                enqueueSnackbar('session Expired, retrying for new session!', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                await dispatch(refreshToken())
                dispatch(createSubCustomer())
            } else if (error.response?.status === 500) {
                enqueueSnackbar('Something went wrong!, Please Try Again later', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                dispatch({
                    type: DATUM_LOADING_FALSE
                })
            } else {
                enqueueSnackbar(error?.response?.data?.data, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center"
                    }
                })
                dispatch({
                    type: DATUM_LOADING_FALSE
                })
            }
        })

    }
}
export const editDatumOrder = (order_id, quantity, enqueueSnackbar, requestbody) => {
    return async (dispatch) => {
        dispatch({
            type: DATUM_LOADING
        })
        reposWebApi.post('/order/edit_datum_order', qs.stringify({
                order_id: order_id,
                quantity: quantity
            }), {
                headers: {
                    Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
                }
            }
        ).then(res => {
            dispatch({
                type: DATUM_ORDER_UPDATE,
                payload: res?.data?.data
            })
            enqueueSnackbar('Dispense order update successfully', {
                variant: "success",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                }
            })
            dispatch(getAllCustomerOrders(requestbody))
        }).catch(async error => {
            if (error.response?.status === 401) {
                enqueueSnackbar('session Expired, retrying for new session!', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                await dispatch(refreshToken())
                dispatch(editDatumOrder())
            }
            enqueueSnackbar('Something went wrong..!, Please Try Again later', {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                }
            })
        })
    }
}
export const handleDeleteSubCustomer = (enqueueSnackbar, handleClose) => {
    return async (dispatch) => {
        dispatch({
            type: DATUM_LOADING
        })
        reposWebApi.post('/customer/manage/subcustomer', qs.stringify({
                add: 0,
                update: 0,
                id: this.state.singleSubcustomer.id,
                delete: 1
            }), {
                headers: {
                    Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
                }
            }
        ).then(res => {
            dispatch({
                type: DATUM_ASSET_DELETE,
                payload: res?.data?.data
            })
            handleClose();
            enqueueSnackbar('Sub-Asset Deleted Successfully', {
                variant: "success",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                }
            })
            dispatch(listAllSubcustomers());
        }).catch(async error => {
            if (error.response?.status === 401) {
                enqueueSnackbar('session Expired, retrying for new session!', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                await dispatch(refreshToken())
                dispatch(handleDeleteSubCustomer())
            }
            enqueueSnackbar('Something went wrong..!, Please Try Again later', {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                }
            })
        })
    }
}

export const updateDetailsSubCustomer = (enqueueSnackbar) => {
    return async (dispatch) => {
        dispatch({
            type: DATUM_LOADING
        })

        reposWebApi.post('customer/manage/subcustomer', qs.stringify(
            {
                add: 0,
                update: 1,
                delete: 0,
                id: this.state.editId,
                name: this.state.editName,
                subasset_type: this.state.editAsset_type,
                mobile: this.state.editMobile,
            }), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        }).then(res => {
            dispatch({
                type: DATUM_ASSET_UPDATE,
                payload: res?.data?.data
            })
            enqueueSnackbar('Sub-Asset Details have been successfully updated', {
                variant: "success",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                }
            })
            this.handleClose();
            this.listAllSubcustomers();
        }).catch(async error => {
            if (error.response?.status === 400) {
                enqueueSnackbar('Mobile already exists and is assigned to another sub-asset!', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
            } else if (error.response?.status === 401) {
                enqueueSnackbar('session Expired, retrying for new session!', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                await dispatch(refreshToken())
                dispatch(updateDetailsSubCustomer())
            } else {
                enqueueSnackbar('Something went wrong..!, Please Try Again later', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
            }
        })

    }
}

export const datumOrder = (formData, enqueueSnackbar, history, setOpenDatum, setQuantity, assetCallData, scrollToPreview) => {
    return (dispatch) => {
        dispatch({
            type: FORGOT_LOADING,
        });
        reposWebApi.post('/order/place_datum_order', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
        })
            .then(async response => {
                enqueueSnackbar("Order placed Successfully", {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                dispatch({
                    type: DATUM_ORDER_SUCCESS,
                    payload: response?.data
                });
                await dispatch(listAllSubcustomers(assetCallData, enqueueSnackbar, scrollToPreview));
                setOpenDatum(false);
                setQuantity(0);
                dispatch(clearSubAsset());
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
                if (error?.response?.status === 406) {
                    dispatch({
                        type: DATUM_ORDER_ERROR,
                    })
                }
                enqueueSnackbar("Something went wrong", {
                    variant: 'error', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                dispatch({
                    type: DATUM_ORDER_ERROR,
                    payload: error
                });
            })
    }
};

export const shellTelematics = (id, enqueueSnackbar, scrollToPreview, requestBody, formData) => {
    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;

    return async (dispatch) => {
        dispatch({
            type: SHELL_LOADING
        })
        reposWebApi.get('/order/shell_details', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: {
                order_id: id
            },
        }).then(res => {
            dispatch({
                type: SHELL_DATA,
                payload: res?.data?.data
            })
            dispatch(getAllCustomerOrders(requestBody, scrollToPreview));
            dispatch(getDatumRefillReports(formData, enqueueSnackbar))
        }).catch(async (error) => {
            if (error?.response?.status === 401) {
                enqueueSnackbar('session Expired, retrying for new session!', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                await dispatch(refreshToken())
                dispatch(shellTelematics(id, enqueueSnackbar, scrollToPreview, requestBody))
            } else {
                enqueueSnackbar('Something went wrong, Please Try Again later', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
            }
            dispatch(getAllCustomerOrders());
        })
    }
}

export const get_telematics_types = () => {
    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;

    return async (dispatch) => {
        dispatch({
            type: SHELL_LOADING
        })
        reposWebApi.get('/customer/get_telematics_types', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
        }).then(res => {
            dispatch({
                type: GET_TELEMATICS_TYPE,
                payload: res?.data?.data
            })
        }).catch(async (error) => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(get_telematics_types())
            } else {
                console.log(error?.response)
            }
        })
    }
}

export const datumXSensorData = (name, enqueueSnackbar) => {
    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;

    return async (dispatch) => {
        dispatch({
            type: SENSOR_DATA_LOADING
        })
        nodeStaging.get('/sensor/getsensordata', {
            // headers: {
            //     Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            // },
            params: {
                name: name
            },
            signal: signal,
        }).then(res => {
            dispatch({
                type: SENSOR_DATA,
                payload: res?.data?.data
            })
        }).catch(async (error) => {
            if (error?.response?.status === 401) {
                enqueueSnackbar('session Expired, retrying for new session!', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                await dispatch(refreshToken())
                await dispatch(datumXSensorData())
            } else {
                enqueueSnackbar('Something went wrong, Please Try Again later', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
            }
        })
    }
}

export const datumXHistoricData = (formData, enqueueSnackbar, fetchMoreData) => {
    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;

    return (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: ASSET_LOADING,
            });
            dispatch(clearAssetData())
        }
        reposWebApi
            .get("/customer/get_historic_data", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
                },
                signal: signal,
                params: formData,
            })
            .then((response) => {
                dispatch({
                    type: GET_DISPENSE_REPORTS,
                    payload: response?.data?.data,
                    // .data.results,
                });
            })
            .catch(async (error) => {
                if (error.response?.status === 401) {
                    enqueueSnackbar("Session Expired..!", {
                        variant: 'warning',
                        horizontal: 'center'
                    });
                    await dispatch(refreshToken());
                    dispatch(datumXHistoricData(formData, enqueueSnackbar));
                } else {
                    enqueueSnackbar("'Something went wrong..! Please Try Again later'", {
                        variant: 'error',
                        horizontal: 'center'
                    });
                    dispatch({
                        type: GET_DISPENSE_REPORTS_FAIL,
                        payload: true,
                    });
                }
            });
    };

}

export const getSubAssetRefillGraphDetails = (formData, loadChart) => {
    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return async (dispatch) => {
        dispatch({
            type: ASSET_LOADING,
        })
        await reposWebApi.get('/customer/sub_asset/refill_graph', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: formData
        }).then(response => {
            dispatch({
                type: GET_DATUM_GRAPH_DETAILS,
                payload: response?.data?.data
            });
            loadChart(response?.data?.data);
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getDatumGraphDetails())
            } else dispatch({
                type: GET_DATUM_GRAPH_DETAILS_FAIL,
                payload: error
            })
        });
    }
};

export const getDatumRefillReports = (formData, enqueueSnackbar, fetchMoreData) => {
    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;

    return (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: ASSET_LOADING,
            });
        }
        reposWebApi.get("/customer/sub_asset/refill_list", {
            headers: {
                Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
            },
            signal: signal,
            params: formData,
        })
            .then((response) => {
                dispatch({
                    type: GET_REFILL_REPORTS,
                    payload: response?.data?.data,
                });
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(getDatumRefillReports(formData, enqueueSnackbar, fetchMoreData));
                } else if (error?.response?.status === 404) {
                    enqueueSnackbar(error?.response?.data?.data?.error, {
                        variant: 'info',
                        horizontal: 'center'
                    });
                    dispatch({
                        type: GET_REFILL_REPORTS_NOT_FOUND
                    });
                } else if (error?.response?.status === 500 || error?.response?.status === 501 || error?.response?.status === 502) {
                    enqueueSnackbar("Something Went Wrong..!", {
                        variant: 'error',
                        horizontal: 'center'
                    });
                    dispatch({
                        type: GET_REFILL_REPORTS_FAIL,
                        payload: true,
                    });
                } else {

                    dispatch({
                        type: GET_REFILL_REPORTS_FAIL,
                        payload: true,
                    });
                }
            });
    };
};

export const getRCDetails = (formData, enqueueSnackbar) => {


    return (dispatch) => {

        reposWebApi.get("/customer/get_rc_details", {
            headers: {
                Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
            },
            params: formData,
        })
            .then((response) => {
                dispatch({
                    type: GET_RC_DETAILS,
                    payload: response?.data?.data,
                });
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(getRCDetails(formData));
                } else if (error?.response?.status === 404) {
                    enqueueSnackbar(error?.response?.data?.message, {
                        variant: 'info',
                        horizontal: 'center'
                    });
                    dispatch({
                        type: GET_RC_DETAILS_NOT_FOUND
                    });
                } else if (error?.response?.status === 400) {
                    enqueueSnackbar("RC Detail Not Registered", {
                        variant: 'error',
                        horizontal: 'center'
                    });
                    dispatch({
                        type: GET_RC_DETAILS_FAIL,
                        payload: true,
                    });
                } else {
                    enqueueSnackbar("Something Went Wrong..!", {
                        variant: 'error',
                        horizontal: 'center'
                    });
                    dispatch({
                        type: GET_RC_DETAILS_FAIL,
                        payload: true,
                    });
                }
            });
    };
};


export const getSingleSubAsset = (subAsset) => {
    return (dispatch) => {
        dispatch({
            type: GET_SINGLE_SUB_ASSET,
            payload: subAsset,
        });
    };
};

export const clearSubAsset = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SUB_ASSET,
        })
    }

};

export const handleCheckStatus = (value, handleStatusCall) => {
    let data = {
        "order_id": value
    }
    return (dispatch) => {
        reposWebApi.get('/order/get_order_status', {
            // headers: {
            //     Authorization: 'Bearer ' + 'EF94hjai5hKIy84brbf'
            // }
            params: data,
        }).then(res => {
            if (res.data.data.status !== "COM") {
                handleStatusCall(value);
            }
            dispatch({
                type: DATUM_ORDER_STATUS,
                payload: res.data.data
            })
        }).catch(err => {
            console.log(err);
        })
    }

}

export const datumEnquiry = (history,enqueueSnackbar) => {
    return (dispatch) => {

        reposWebApi.get("/customer/get_datum_enquiry", {
            headers: {
                Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
            },
        })
            .then((response) => {

                dispatch({
                    type: GET_DATUM_ENQUIRY,
                });

                history.goBack("/dashboard")
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    enqueueSnackbar(error?.response?.data?.message, {
                        variant: 'info',
                        horizontal: 'center'
                    });
                } else if (error?.response?.status === 404) {
                    enqueueSnackbar(error?.response?.data?.message, {
                        variant: 'info',
                        horizontal: 'center'
                    });
                } else {
                    dispatch({
                        type: GET_DATUM_ENQUIRY_FAIL,
                        payload: true,
                    });
                }
            });
    };
}

export const setCheckStatusNull = () => {
    return (dispatch) => {
        dispatch({
            type: DATUM_ORDER_STATUS_NULL,
        })
    }
}

export const setData = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_DATA,
            payload: data
        })
    }
}

export const getAllELocks = (requestBody, scrollToPreview, isDashoard,fetchMoreData) => {
    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;
    return async (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: ELOCK_LOADING,
                payload: false
            })
        }
        reposWebApi.get('/customer/get_elogs', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: requestBody,
        }).then(res => {
            dispatch({
                type: ALL_ELOCK,
                payload: res?.data?.data
            })
            scrollToPreview();
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getAllELocks(requestBody, scrollToPreview))
            } else dispatch({
                type: SOMETHING_WRONG,
                payload: error
            });
        })
    }
}

export const acceptRejectElock = (event, log_id, decision, enqueueSnackbar, params, scrollToPreview) => {

    return async (dispatch) => {
        dispatch({
            type: ELOCK_LOADING
        })
        const requestBody = {
            log_id,
            decision
        }
        reposWebApi.post('/customer/customer_elock_accept_reject', qs.stringify(requestBody), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        }).then(res => {
            if (res.status === 200) {
                const message = res.data.data.toLowerCase();
            
                if (message.includes("accept")) {
                    enqueueSnackbar("Elock Accepted!", {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                } else if (message.includes("reject")) {
                    enqueueSnackbar("Elock Rejected!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        }
                    });
                }
                // console.log("res",res.data.data)
                dispatch({
                    type: ELOCK_ACCEPT
                })
                dispatch(clearSubAsset());
                dispatch(getAllELocks(params, scrollToPreview, false));
            } else {
                enqueueSnackbar('Elock Rejected!', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                dispatch(clearSubAsset());
                dispatch(getAllELocks(params, scrollToPreview, false));
            }
        }).catch((error) => {
            if (error.response.status === 202) {
                enqueueSnackbar('Rejected!', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
            }
            dispatch(getAllELocks());
        })
    }
}

export const getAssetList = (requestBody) => {

    return async (dispatch) => {
        // dispatch({
        //     type: ASSET_LOADING,
        // })
        await reposWebApi.get('/customer/get_assets_list', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            params: requestBody,
        }).then(response => {
            dispatch({
                type: GET_ASSETS_LIST,
                payload: response?.data?.data
            });
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getAssetList())
            } else dispatch({
                type: GET_ASSET_FAIL,
                payload: error
            })
        });
    }
};
